<template>
  <div>
    <div class="main_visual">
      <swiper class="swiper" ref="mainSwiper" :options="swiperOptions">
        <swiper-slide class="slide-wrap bg1">
          <div class="wrap">
            <div class="slide-content">
              <strong>{{ $t("text.main.slide_title_1") }}</strong>
              <p>
                {{ $t("text.main.slide_describe_1") }}
              </p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slide-wrap bg2">
          <div class="wrap">
            <div class="slide-content">
              <strong>{{ $t("text.main.slide_title_2") }}</strong>
              <p>
                {{ $t("text.main.slide_describe_2") }}
              </p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="slide-wrap bg3">
          <div class="wrap">
            <div class="slide-content">
              <strong>{{ $t("text.main.slide_title_3") }}</strong>
              <p>
                {{ $t("text.main.slide_describe_3") }}
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="wrap">
        <div class="pagination-wrap">
          <div class="swiper-pagination"></div>
          <button class="play">
            <i
              class="material-icons-outlined"
              v-if="stopbtn === 'y'"
              @click="swiper_stop"
              >pause</i
            >
            <i
              class="material-icons"
              v-if="playbtn === 'y'"
              @click="swiper_play"
              >play_arrow</i
            >
          </button>
        </div>
      </div>
    </div>

    <div class="championship wrap">
      <h2 class="section-title text-capitalize" @click="goto_url('tournament')">
        {{ $t("title.main.tournament")
        }}<i class="material-icons">navigate_next</i>
      </h2>
      <span class="section-describe">{{
        $t("text.main.tournament_describe")
      }}</span>
      <Tournament v-bind:tournamentlist="tournamenttop" />
    </div>

    <div class="bg-wrap">
      <!-- <div class="main_lesson">
        <div class="wrap">
          <h2 class="section-title" @click="goto_url('lesson')">
            {{ $t("title.main.netizen_lesson") }}<i class="material-icons">navigate_next</i>
          </h2>
          <span class="section-describe">{{ $t("text.main.netizen_lesson_describe") }}</span>
          <Lesson v-bind:swinglist="swingtop" />
        </div>
      </div> -->
      <div class="main_cc">
        <div class="wrap">
          <Course v-bind:courselist="coursetop" />
        </div>
      </div>
    </div>

    <!-- <div class="main_social">
      <div class="wrap text-center">
        <a href="https://www.instagram.com/xgolfofficial/" target="_blank">
          <h2 class="section-title">
            {{ $t("title.main.social_media")
            }}<i class="material-icons">navigate_next</i>
          </h2>
        </a>
        <span class="section-describe">{{
          $t("text.main.social_media_describe")
        }}</span>
      </div>
      <Socialmedia />
    </div> -->
  </div>
</template>

<script>
import Tournament from "@/components/main/Tournament";
import Lesson from "@/components/main/Lesson";
import Course from "@/components/main/Course";
import Socialmedia from "@/components/main/Socialmedia";
import ax from "@/api/main";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import i18n from "@/i18n";

export default {
  name: "Main",
  data() {
    return {
      tournamenttop: [],
      swingtop: [],
      coursetop: [],
      playbtn: "n",
      stopbtn: "y",
      swiperOptions: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        loop: true,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            if (index === 0) {
              return (
                '<span class="' +
                className +
                '">' +
                i18n.t("text.main.slide_pagination_1") +
                "</span>"
              );
            } else if (index === 1) {
              return (
                '<span class="' +
                className +
                '">' +
                i18n.t("text.main.slide_pagination_2") +
                "</span>"
              );
            } else if (index === 2) {
              return (
                '<span class="' +
                className +
                '">' +
                i18n.t("text.main.slide_pagination_3") +
                "</span>"
              );
            }
          },
        },
      },
    };
  },
  components: {
    Tournament,
    Lesson,
    Socialmedia,
    Course,
    Swiper,
    SwiperSlide,
  },
  methods: {
    get_main() {
      ax.get_main((flag, data) => {
        if (flag) {
          this.tournamenttop = data.tournamenttop;
          this.swingtop = data.swingtop;
          this.coursetop = data.coursetop;
        } else {
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    goto_url(type) {
      if (type === "tournament") {
        this.$router.push("/tournament/tournamentschedule");
      } else if (type === "lesson") {
        this.$router.push("/event/netizenlesson");
      }
    },
    swiper_stop() {
      this.$refs.mainSwiper.$swiper.autoplay.stop();
      this.stopbtn = "n";
      this.playbtn = "y";
    },
    swiper_play() {
      this.$refs.mainSwiper.$swiper.autoplay.start();
      this.stopbtn = "y";
      this.playbtn = "n";
    },
  },
  created() {
    this.get_main();
  },
};
</script>
