<template>
  <div class="slide">
    <div class="slide-wrapper">
      <swiper class="swiper" ref="swiper-social" :options="swiperOptions">
        <swiper-slide
          v-for="(insta, index) in insta_data.data"
          :key="index + 'i'"
        >
          <a :href="insta.permalink" target="_blank">
            <i><img src="/images/ico_social4.png" /></i>
            <span
              class="d-block insta_img"
              :style="{
                backgroundImage: 'url(' + insta.img_url + ')',
              }"
            >
              <img src="/img/clear.png" class="d-block w-100" />
            </span>
            <p>{{ insta.username }}</p>
            <div>
              <!-- <span class="text-white d-inline-block"><i class="material-icons font-16 mr-1">favorite</i>13</span> -->
              <!-- <span class="text-white d-inline-block"><i class="material-icons font-16 mr-1">textsms</i>0</span> -->
              <span class="d-inline-block text-date fs-14">
                <i class="material-icons-outlined">watch_later</i
                >{{ insta_date(insta.timestamp) }}
              </span>
            </div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <div class="slide-btn">
      <a class="prev s_prev"
        ><i class="material-icons-round">arrow_back_ios</i></a
      >
      <a
        class="more_btn"
        target="_blank"
        href="https://www.instagram.com/xgolfofficial/"
        ><i class="material-icons-outlined">add</i></a
      >
      <a class="next s_next"
        ><i class="material-icons-round">arrow_forward_ios</i></a
      >
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ax from "@/api/main";
export default {
  name: "Socialmedia",
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    get_instagram() {
      ax.get_instagram(this.token, (flag, data) => {
        if (flag) {
          this.insta_data = data;
          for (let i = 0; i < this.insta_data.data.length; i++) {
            this.insta_data.data[i].img_url =
              this.insta_data.data[i].media_type === "VIDEO"
                ? this.insta_data.data[i].thumbnail_url
                : this.insta_data.data[i].media_url;
          }
        } else {
          alert(data);
        }
      });
    },
    insta_date(date) {
      let today = new Date();
      let reg = new Date(date);
      let diffTime = (today.getTime() - reg.getTime()) / (1000 * 60 * 60);
      diffTime = Math.round(diffTime);
      if (diffTime < 24) return diffTime + "h ago";
      else
        return (
          reg.getFullYear() + "-" + (reg.getMonth() + 1) + "-" + reg.getDate()
        );
    },
  },
  data() {
    return {
      swiperOptions: {
        //loop: true,
        navigation: {
          nextEl: ".s_next",
          prevEl: ".s_prev",
        },
        slidesPerView: 1.4,
        spaceBetween: 24,
        breakpoints: {
          576: {
            slidesPerView: 2.4,
          },
          768: {
            slidesPerView: 3.4,
          },
          992: {
            spaceBetween: 24,
            slidesPerView: 4.4,
          },
          1400: {
            spaceBetween: 48,
            slidesPerView: 5.4,
          },
        },
      },
      token:
        "EAAOvI4SeTBABO4z3A383ucOASqeSMP7no5e7GG5mQiUCQQP1jWtFZBGn339LaNoSC8pMvo3TRcSJ0ympByXB6X9OxhSQh5PqVy6L364cS1DDqhyN5kd0tbzB5TcZCxXtfNXySB6euE8eGwiNU7thDJOtqhZB2xW8kojN0Iu8JHLEfNzmPesMyrQSZCQ3PccVNtoZBRpVpEgZDZD",
      insta_data: [],
    };
  },
  //참조하고 있는 값이 변경될 때마다 정의된 계산식에 따라 값을 출력
  computed: {
    swiper() {
      return this.$refs.swiper - social.$swiper;
    },
  },
  created() {
    this.get_instagram();
  },
};
</script>
